import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    padding: [[10, 20, 30]],
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '80rem',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    container: {
      padding: [['3rem', 0]],
      minHeight: '120rem',
    },
  },
}))

export default useStyles
